$(document).ready(function(){
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		dataType: "json",
	});
});

$(function(){
});

function numberFormat(num){
	return num.toString().replace(/([0-9]+?)(?=(?:[0-9]{3})+$)/g , '$1,');
}

function dropdown() {
	$('.dropdownbtn').click( function() {
		$this = $(this);
		var list = $this.next('.dropdownlist');

		if($this.hasClass('open')) {
			$this.removeClass('open');
			list.slideUp();
		} else {
			$this.addClass('open');
			list.slideDown();
		}
	})
}

function spNav() {
	$('.menubtn').click( function() {
		$this = $(this);
		var w = $(window).width();
		var nav = $('.drawernav');

		if(w < 641) {
			if($this.hasClass('open')) {
				$this.removeClass('open');
				nav.slideUp();
			} else {
				$this.addClass('open');
				nav.slideDown();
			}
		}
	})

	$(window).on('load resize', function() {
		var w = $(window).width();
		var btn = $('.menubtn');
		var nav = $('.drawernav');

		if(w > 640) {
			btn.removeClass('open');
			nav.removeAttr('style');
		}
	})
}

$( function() {
	dropdown();
	spNav();
})